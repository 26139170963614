const MEDICAL_VALUABLE_IMAGE = new Map()

MEDICAL_VALUABLE_IMAGE.set("CPAP",'https://storage.googleapis.com/pettag/qr/assets/cpap.png')

MEDICAL_VALUABLE_IMAGE.set("Nebulizer",'https://storage.googleapis.com/pettag/qr/assets/nebulizer.png')
MEDICAL_VALUABLE_IMAGE.set("Diabetes Supplies",'https://storage.googleapis.com/pettag/qr/assets/diabetessupplies.png')
MEDICAL_VALUABLE_IMAGE.set("Portable Oxygen Concentrator",'https://storage.googleapis.com/pettag/qr/assets/portableconcentrator.png')

MEDICAL_VALUABLE_IMAGE.set("Respiratory Machines/Accessories",'https://storage.googleapis.com/pettag/qr/assets/respiratorymachine.png')
MEDICAL_VALUABLE_IMAGE.set("Medical Equipment",'https://storage.googleapis.com/pettag/qr/assets/medical-equipment.png')
MEDICAL_VALUABLE_IMAGE.set("Portable EKG/ECG Machine",'https://storage.googleapis.com/pettag/qr/assets/ecg.png')
MEDICAL_VALUABLE_IMAGE.set("Portable Defibrillators Machine",'https://storage.googleapis.com/pettag/qr/assets/defibrillator.png')
MEDICAL_VALUABLE_IMAGE.set("Insulin Pumps",'https://storage.googleapis.com/pettag/qr/assets/insulinpump.png')
MEDICAL_VALUABLE_IMAGE.set("Respiratory Rate Monitor",'https://storage.googleapis.com/pettag/qr/assets/ratemonitor.png')
MEDICAL_VALUABLE_IMAGE.set("Portable I.V Pump",'https://storage.googleapis.com/pettag/qr/assets/ivpump.png')
MEDICAL_VALUABLE_IMAGE.set("TENS Machine",'https://storage.googleapis.com/pettag/qr/assets/tens.png')
MEDICAL_VALUABLE_IMAGE.set("Portable Chemotherapy Pumps",'https://storage.googleapis.com/pettag/qr/assets/chemopump.png')
MEDICAL_VALUABLE_IMAGE.set("Blood Pressure Monitors",'https://storage.googleapis.com/pettag/qr/assets/bp.png')
MEDICAL_VALUABLE_IMAGE.set("Cooling Caps",'https://storage.googleapis.com/pettag/qr/assets/coldcap.png')



export default MEDICAL_VALUABLE_IMAGE